import { render, staticRenderFns } from "./NotFoundView.vue?vue&type=template&id=ef640480&scoped=true&"
import script from "./NotFoundView.vue?vue&type=script&lang=js&"
export * from "./NotFoundView.vue?vue&type=script&lang=js&"
import style0 from "./NotFoundView.vue?vue&type=style&index=0&id=ef640480&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef640480",
  null
  
)

component.options.__file = "NotFoundView.vue"
export default component.exports