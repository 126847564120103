<template>
	<MhView :isLoading="isLoading">
		
		<template v-if="$root.isSmallDevice">		
			<MobilePageTitle></MobilePageTitle>		
		</template>			
		
		<div class="hSpace--big vSpace vSpace--default">
			Error 404.<br />
			Page not found.
		</div>
		
	</MhView>
</template>

<script>
	// @ is an alias to /src
	//import SearchPosts from '@/components/SearchPosts.vue'
	import MhView from '@/components/MhView/MhView.vue'
	import MobilePageTitle from '@/components/MobilePageTitle.vue'

	export default {
		name: 'notFoundView',
		components: {
			MhView,
			MobilePageTitle,
		},
		props: {
		},
		data() {
			return {
				isLoading: true,
			}
		},
		mounted() {
			this.isLoading = false
		}
	}
</script>

<style lang="less" scoped>	
	.notFoundView {}
</style>
